export default {
    id: null,
    waybillable_id: null,
    waybillable_type: null,
    instructable_id: null,
    instructable_type: null,
    instruction_type_id: null,
    waybill_id: null,
    order_product_id: null,
    tare_amount: null,
    gross_amount: null,
    net_amount: null,
    unit_id: null,
    description: "",
    part_number: null,
    fabric_roll: null,
    store_id: null,
    transaction_type: null,
    supplier_company_id: null,
    waybillable_ids: [],
    extraAmount: [],
    totalAmounts: [],
    divided_products: [],
    orderOptions: [],
    instructable: null,
    order_amount_unit_id: null,
    remaining_amount_unit_id: null,
    tare_amount_unit_id: null,
    gross_amount_unit_id: null,
    net_amount_unit_id: null,
    order_amount: null,
    document: null,
    waybillable: null,
    product_color_id: null,
    showOrderDetail: true,
    isSubmitted: false,
    showDescription: false
};
