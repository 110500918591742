import store from "@/core/services/store";
import baseModule, {
    BASE_URL,
    ITEMS_ALL,
    MODULE_NAME,
    SET_ITEMS_ALL,
    GET_ITEMS_ALL
} from "@/core/services/store/supplier-company/supplier.company.module";

const _MODULE_NAME = MODULE_NAME;

export default {
    beforeCreate() {
        function registerStoreModule(moduleName, storeModule) {
            if (!(store && store.state && store.state[moduleName])) {
                store.registerModule(moduleName, storeModule)
            }
        }

        registerStoreModule(_MODULE_NAME, baseModule)
    },
    data() {
        return {};
    },
    computed: {
        supplierCompanyAllItems() {
            return store.getters[_MODULE_NAME + '/' + ITEMS_ALL];
        },
    },
    methods: {
        getSupplierCompanyAllItems(){
            this.$store.dispatch(_MODULE_NAME + '/' + GET_ITEMS_ALL, {
                filters: {},
                url: BASE_URL
            })
        },
    },
    mounted() {
        if (!this.supplierCompanyAllItems || this.supplierCompanyAllItems.length  < 0 ) {
            this.$store.commit(_MODULE_NAME + '/' + SET_ITEMS_ALL, null);
            this.getSupplierCompanyAllItems();
        }
    }
};
