import store from "@/core/services/store";
import baseModuleWaybillType, {
    BASE_URL,
    ITEMS_ALL,
    MODULE_NAME as WAYBILL_TYPE_MODULE_NAME,
    SET_ITEMS_ALL,
    GET_ITEMS_ALL
} from "@/core/services/store/waybill-type/waybillType.module";

const _MODULE_NAME_WAYBILL_TYPE = WAYBILL_TYPE_MODULE_NAME;

export default {
    beforeCreate() {
        function registerStoreModule(moduleName, storeModule) {
            if (!(store && store.state && store.state[moduleName])) {
                store.registerModule(moduleName, storeModule)
            }
        }

        registerStoreModule(_MODULE_NAME_WAYBILL_TYPE, baseModuleWaybillType)
    },
    data() {
        return {};
    },
    computed: {
        waybillTypeAllItems() {
            return store.getters[_MODULE_NAME_WAYBILL_TYPE + '/' + ITEMS_ALL];
        },
        filters() {
            return {}
        }
    },
    methods: {
        getWaybillTypeAllItems(){
            this.$store.dispatch(_MODULE_NAME_WAYBILL_TYPE + '/' + GET_ITEMS_ALL, {
                filters: this.filters,
                url: BASE_URL
            })
        },
    },
    mounted() {
        if (!this.waybillTypeAllItems || this.waybillTypeAllItems.length  < 0 ) {
            this.$store.commit(_MODULE_NAME_WAYBILL_TYPE + '/' + SET_ITEMS_ALL, null);
            this.getWaybillTypeAllItems();
        }
    }
};
